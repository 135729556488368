.goals-page {
  background-color: #fff;
  color: #000;
  white-space: pre-wrap;

  &#peopleGoals {
    section .row > div {
      max-width: 16.44vw;
    }
  }

  &#environmentGoals {
    section .row > div {
      max-width: 9.4vw;
    }
  }
  
  &#communityGoals {
    section .row > div {
      max-width: 16.38vw;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    padding: 1.8vw 3.9vw;

    h1 {
      margin: 0 0 0.8vw -0.2vw;
      font-size: 9.8vw;
      text-transform: uppercase;
      font-family: 'helvetica_lt_sultracompressed';
      font-weight: normal;
      color: #f15a31;
    }

    .row {
      display: flex;
      justify-content: center;

      > div {
        padding: 0 1.1vw;
        border-right: 1px dotted #000;
        height: 33vw;

        &:first-child {
          border-left: 1px dotted #000;
        }

        &:last-child {
          border-right: none;
          padding-right: 0;
        }

        .tiles {
          display: flex;
          gap: 0.9vw;

          img {
            width: 3.9vw;
            height: auto;
          }
        }
  
        h2 {
          margin: 1.3vw 0 0 0;
          font-size: 0.92vw;
          font-weight: normal;
          line-height: 1.02vw;
          letter-spacing: 0.016vw;
          width: 101.3%;
        }
  
        p {
          margin-top: 0.6vw;
          margin-bottom: 0;
          font-size: 0.7vw;
          font-weight: bold;
          line-height: 0.9vw;
          letter-spacing: 0.007vw;
        }

        ul {
          width: 100%;
          font-size: 0.7vw;
          margin-top: 0.5vw;
          margin-bottom: 0.48vw;
          padding-left: 0.5vw;
          line-height: 0.91vw;
          list-style: none;
          color: #747e85;

          li {
            position: relative;
            margin-top: 0.3vw;
            // letter-spacing: -0.017vw;

            &::before {
              content: "•";
              color: #747e85;
              font-size: 0.6vw;
              position: absolute;
              left: -0.5vw;
            }

            &:first-child {
              margin-top: 0;
            }
          }
        }

        a {
          display: block;
          font-size: 0.79vw;
          line-height: 0.9vw;
          text-decoration: none;
          color: #f15a31;
          letter-spacing: -0.004vw;

          b {
            font-family: 'helvetica_neue_lt_std87HvCn';
          }
        }
      }
    }
  }
}