footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.83vw;
  background-color: #f15a31;
  text-transform: uppercase;
  padding: 0 4.078vw;

  ul {
    display: flex;
    align-items: center;
    gap: 3.8vw;
    padding: 0;
    margin: 0;
    height: 100%;
    list-style: none;
    font-family: 'helvetica_lt_sultracompressed';
    font-size: 2vw;
    letter-spacing: 0.03vw;

    svg {
      width: 1.8vw;
    }

    li {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;

      @mixin nav-active-after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 0.7vw;
        background: url('../../../public/images/nav_active.svg') no-repeat center;
        color: #fff;
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #E9DF97;

        &:hover, &:active, &.active {
          color: #fff;
          &::after {
            @include nav-active-after;
          }
        }
      }

      &.home{
        a, a.active {
          &:hover, &:active {
            &::after {
              background: none;
            }
          }
        }
      }
    }
  }

  .footer-right {
    display: flex;
    align-items: center;
    gap: 1.15vw;
    color: #fff;
    font-size: 1.202vw;
    margin-right: 7.75vw;

    span {
      margin-bottom: 0.2vw;
    }

    button {
      background: none;
      border: none;
      color: #fff;
      font-family: 'zapf_dingbatsregular';
      font-size: 1.41vw;
      
      svg {
        width: 1.1vw;
      }
    }
  }
}