#intro {

  div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      color: #fff;
    }

    img {
      width: 15.2vw;
      height: 15.2vw;
    }
  }

  video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
}