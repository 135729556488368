.category-page {

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .left {
    position: absolute;
    top: 11.6vw;
    left: 3.6vw;

    h1 {
      margin-bottom: 0.52vw;
      font-family: 'helvetica_lt_sultracompressed';
      font-size: 9.8vw;
      text-transform: uppercase;
      letter-spacing: 0.008vw;
      text-shadow: 0.3vw 0.3vw 0.3vw #000000b0;
      font-weight: normal;
    }

    .tiles {
      display: flex;
      gap: 0.9vw;
      flex-wrap: wrap;
      max-width: 31.2vw;
      margin-left: 0.25vw;

      img {
        width: 4.42vw;
        height: 4.42vw;
      }
    }
  }

  .right {
    position: absolute;
    top: 5.4vw;
    right: 3.8vw;
    padding: 0 2.4vw;
    width: 30.5vw;
    height: 35.4vw;
    background-color: #5B6770;
    opacity: 0.85;

    :nth-child(2) {
      margin-top: 0;
    }

    > * {
      font-size: 1.755vw;
      line-height: 2.203vw;
      letter-spacing: 0.026vw;
      white-space: pre-wrap;
    }

    h2 {
      font-family: "helvetica_neue_lt_std77BdCn";
      font-size: 2.24vw;
      text-align: center;
      margin-top: 3.3vw;
      margin-bottom: 1.4vw;
      line-height: 2.3vw;
    }
  }
}