.case-study {
  display: flex;
  background-color: #fff;

    .left {
      padding: 3.7vw 0 0 3.9vw;
      color: #5b6770;
      white-space: pre-wrap;
      h1 {
        margin-top: 0;
        margin-bottom: 0.5vw;
        font-family: 'helvetica_lt_sultracompressed';
        font-size: 2.9vw;
        color: #f15a31;
        white-space: pre-wrap;
        text-transform: uppercase;
        letter-spacing: 0.081vw;
        line-height: 2.9vw;
        span {
          display: block;
          margin-bottom: 1.9vw;
          font-family: 'helvetica_neue_lt_std77BdCn';
          text-transform: capitalize;
          font-size: 0.944vw;
          letter-spacing: normal;
          line-height: normal;
          color: #000;
        }
      }
      p {
        margin-top: 0;
        margin-bottom: 0.96vw;
        // letter-spacing: 0.029vw;
        line-height: 1.53vw;
      }
      ul {
        padding-left: 1vw;
        margin-bottom: 0.95vw;

        li {
          margin-bottom: 0.96vw;
          line-height: 1.53vw;
        }

        &.gt7 {
          li {
            margin-bottom: 0.1vw;
            line-height: 1.435vw;
          }
        }
      }
    }

    .right {
      position: relative;
      margin: 3.9vw 3.9vw 0 3.9vw;
      > img {
        width: 57vw;
        height: 44.5vw;
        object-fit: cover;
      }
      .content {
        position: absolute;
        top: 0;
        right: 0;
        width: 12.9vw;
        background-color: rgba(91, 103, 112, 0.9);
        font-family: 'scala_sans_otblack';
        color: #fff;
        
        > * {
          white-space: pre-wrap;
        }

        .tiles {
          position: absolute;
          top: -2.2vw;
          display: flex;
          justify-content: center;
          gap: 0.7vw;
          width: 100%;
          z-index: 1;

          img {
            opacity: 1;
            width: 4.3vw;
            height: 4.3vw;
          }

          &.gt2 {
            justify-content: flex-end;

            img {
              &:last-child {
                margin-right: 1.8vw;
              }
            }
          }
        }

        ul {
          margin-top: 4.2vw;
          margin-bottom: 1.6vw;
          font-size: 0.7vw;
          text-transform: uppercase;
          list-style: none;
          padding: 0 0.7vw;
          letter-spacing: 0.0585vw;
          line-height: 1.5vw;

          li {
            margin-top: 0.5vw;
          }
        }

        & > img {
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }
}